import { useEffect } from "react";

const Play = () => {
  useEffect(() => {
    window.location.href = "https://open.spotify.com/track/00iwQgjIgoGO94BRBDyClo?si=xP8HDAnaSoSnP5tdljiEfQ";
  }, []);
  return <div></div>;
};

export default Play;
