import { useEffect } from "react";

const Password = () => {
  useEffect(() => {
    window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
  }, []);
  return <div></div>;
};

export default Password;
