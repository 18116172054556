import { Card, Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import "./Skeletor.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function Skeletor() {
  const classes = useStyles();

  const randomNumber = () => {
    return Math.floor(Math.random() * 36 + 1);
  };

  const [imgNumber, setImgNumber] = useState(randomNumber());

  const handleClick = () => {
    setImgNumber(randomNumber);
  };

  return (
    <>
      <Grid
        container
        className={classes.root}
        justifyContent="space-around"
        alignContent="space-around"
        onClick={handleClick}
      >
        <Grid item xs={11} lg={6}>
          <h1 className="font">Skeletor Positive Random Meme</h1>
          <h4 className="font">(click to get a new meme)</h4>
          <Card className="memeCard">
            <img
              alt="skeletor meme"
              src={`/images/skeletor/${imgNumber}.jpeg`}
              className="meme"
            ></img>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Skeletor;
