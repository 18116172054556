import { Card, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: { flexGrow: 1 },
  items: { flexGrow: 1, height: "" },
  card: { display: "flex", height: "250px" },
  img: {
    width: "80%",
    height: "min-content",
    padding: " 0% 10%",
    margin: "auto",
  },
  narcis: { textAlign: "center", width: "100%", alignSelf: "center" },
  description: { color: "black" },
}));

function MiscHome() {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        className={classes.root}
        justifyContent="space-around"
        alignContent="space-around"
      >
        <Grid item xs={12} lg={12}>
          <h1 className="font text-center">Welcome to my Sandbox</h1>
          <h4 className="font text-center">
            A collection of random stuff I built
          </h4>
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.root}
        justifyContent="space-around"
        alignContent="space-around"
        spacing={10}
      >
        <Grid item xs={11} lg={3}>
          <Link to={`/skeletor`}>
            <Card className={classes.card}>
              <img
                className={classes.img}
                alt="skeletor memes"
                src="/images/skeletor/1.jpeg"
              ></img>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={11} lg={3}>
          <Link to={`/narcis`}>
            <Card className={classes.card}>
              <h1 className={classes.narcis}>Il mio nome è ...</h1>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={11} lg={3}>
          <Link to={`/cv`}>
            <Card className={classes.card}>
              <h1 className={classes.narcis}>CV</h1>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default MiscHome;
