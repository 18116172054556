import { Card, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, height: "calc(100vh - 125px)" },
  card: { display: "flex", height: "calc(100vh - 250px)" },
  img: { width: "80%", height: "min-content", padding: "10%", margin: "auto" },
  narcis: {
    textAlign: "center",
    width: "100%",
    alignSelf: "center",
    fontSize: "xxx-large",
    userSelect: "none",
  },
  bold: {
    fontWeight: "bolder",
  },
}));

function Narcis() {
  const classes = useStyles();
  const namesArray = [
    "Narcicristo",
    "Narcrist",
    "Cris",
    "Narcos",
    "Nachos",
    "Naz",
    "Narcheese",
    "Narchurros",
    "Narcho",
    "Jason",
    "Davide",
    "Narci",
    "NastroAzzurro",
    "Nemesis",
    "Nerch Oui S.",
    "Narcius",
    "Narcissus Pseudonarcissus",
    "Daffodil",
    "Supremo",
    "Narcius Maximus",
    "Gerotaliano",
    "Narcis Roberto Raffaele Dimitri Mariano Cristea",
    "Narco",
    "Narcolettico"
  ];
  const [name, setName] = useState("Narcos");
  const randomName = () => {
    setName(namesArray[Math.floor(Math.random() * namesArray.length)]);
  };

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.keyCode === 32) {
        randomName();
      }
    });
  });

  return (
    <Grid
      container
      className={classes.root}
      justifyContent="space-around"
      alignContent="space-around"
    >
      <Grid item xs={12} lg={12}>
        <h1 className="font text-center" style={{ userSelect: "none" }}>
          Il mio nome non è Narcis
        </h1>
      </Grid>
      <Grid item xs={11} lg={7}>
        <Card className={classes.card} onClick={randomName}>
          <h1 className={classes.narcis}>Il mio nome è <span className={classes.bold}>{name}</span></h1>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Narcis;
