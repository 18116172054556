import { ThemeProvider, createTheme } from "@material-ui/core";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { CV, MiscHome, Narcis, PageNotFound, Password, Play, Skeletor } from "./components";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#2196f3",
      },
      secondary: {
        main: "#f50057",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path={"/"} element={<MiscHome />}></Route>
          <Route path={`/skeletor`} element={<Skeletor />}></Route>
          <Route path={`/narcis`} element={<Narcis />}></Route>
          <Route path={`/cv`} element={<CV />}></Route>
          <Route path={`/password`} element={<Password />}></Route>
          <Route path={`/play`} element={<Play />}></Route>
          <Route path={`*`} element={<PageNotFound />}></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
