import { useEffect } from "react";

const PageNotFound = () => {
  useEffect(() => {
    document.title = "404 Page Not Found";
    setTimeout(() => {
      window.location.href = "/";
    }, 3000);
  }, []);
  return (
    <div>
      <h1>404 Page Not Found</h1>
      <p>Redirecting to Home Page...</p>
    </div>
  );
};

export default PageNotFound;
